import React from 'react';

import { getNewsPage } from './news.query';

import Container from '../../components/Container/Container';
import Card from '../../components/Card/Card';
import Hero from '../../components/Hero/Hero';

const News = () => {
  const page = getNewsPage();

  const { newsHeroImages, newsHeroHeading } = page.allWordpressPage.edges[0].node.acf;
  const mappedPosts = page.allWordpressPost.edges.map(({ node }) => ({
    content: node.content,
    slug: node.slug,
    id: node.id,
    title: node.title,
    date: node.date,
    featuredMedia: node.featured_media,
  }));

  return (
    <>
      <Hero heading={newsHeroHeading} slides={newsHeroImages} />
      <Container rowTablet justifyCenterTablet padding>
        {mappedPosts.map(({ id, title, slug, featuredMedia }) => (
          <Card
            url={`/wydarzenia/${slug}/`}
            key={id}
            heading={title}
            fluid={featuredMedia.localFile.childImageSharp.fluid}
          />
        ))}
      </Container>
    </>
  );
};

export default News;
