import { useStaticQuery, graphql } from 'gatsby';

// eslint-disable-next-line import/prefer-default-export
export const getNewsPage = () =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useStaticQuery(graphql`
    query {
      allWordpressPage(filter: { id: { eq: "3a6300ac-c869-568b-bfda-6e38a731542e" } }) {
        edges {
          node {
            acf {
              newsHeroHeading
              newsHeroImages {
                image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 900) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      allWordpressPost {
        edges {
          node {
            content
            date
            id
            slug
            title
            featured_media {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
